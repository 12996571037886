import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Container from '../components/Container'
import Article from '../components/Article'
import MetaTag from '../components/MetaTag'
import ExternalLink from '../components/ExternalLink'

const Service = () => {
    return (
        <div className='bg-[#F6F4F2]'>
            <Layout>
                <Article title="service" color="[#626262]">
                    <Container>
                        <section>
                            <h2 className='xl:text-6xl xl:leading-relaxed
                                            lg:text-5xl lg:leading-relaxed
                                            text-3xl leading-relaxed md:mb-0 mb-16'>
                                <span className='xsm:inline-block'>最高に魅力的な</span>
                                <span className='xsm:inline-block'>購買体験が、</span><br />
                                需要のトリガーとなる。
                            </h2>
                            <div className='max-w-fullhd w-3/4 mt-16 
                                            xl:text-xl xl:leading-loose 
                                            lg:text-base lg:leading-loose 
                                            md:text-sm md:leading-loose text-xs leading-loose mb-4'>
                                <p>ONESELは“最高に魅力的な購買体験の提供”をビジョンに掲げ、「メディア」「映像制作」の2つの事業を展開しています。</p>
                                <br />
                                <p>誰もが正しい情報を獲得でき、納得のいく形で購買体験に繋がる。それを「購買体験の最適化」と定義しました。
                                    さらに、映像や写真といったクリエイティブを活用することで生まれる相乗効果により「最高に魅力的な購買体験」へと昇華させる。
                                    「生産者」と「消費者」それぞれがONESELの主幹事業を通じてニーズを確かめ、それを知り新たなユーザーが創出される。
                                    私たちが目指しているのは、そんな需要のトリガーとなることです。
                                </p>
                                <br />
                                <p>
                                    インターネットを通じて誰もが情報発信できるようになり、情報の真偽を見極める事が難しい時代になりました。
                                    しかし、情報を提供する側にある私たちがそこに妥協してはならないと考えています。
                                </p>
                                <br />
                                <p>
                                    情報化社会だからこそ、安心して没入できるコンテンツを創る。
                                    すべては、最高に魅力的な購買体験を末永く提供する為に。
                                </p>
                            </div>
                            <div className='flex md:flex-row-reverse xmd:flex-col items-end xmd:mt-8'>
                                <StaticImage src='../images/ServicePage/ceo.png' alt='ceo_image' className='max-w-[432px] xl:h-[25vh] xl:w-[25vh] h-[250px] w-[250px]'/>
                                <p className='fullhd:w-1/3 xfullhd:mr-8
                                            xl:text-4xl xl:leading-relaxed
                                            lg:text-3xl lg:leading-relaxed
                                            text-2xl leading-relaxed'>
                                    CEO<br />
                                    <span className='xsm:inline-block'>譜久島 基 </span>
                                    <span className='xsm:inline-block xsm:text-sm xsm:pl-4'>Motoi Fukushima</span>
                                </p>
                            </div>
                        </section>
                        <section className='mt-48 md:flex items-center justify-between'>
                            <h2 className='fullhd:text-6xl fullhd:leading-relaxed
                                            2xl:text-5xl 2xl:leading-relaxed
                                            lg:text-3xl lg:leading-relaxed
                                            text-2xl leading-relaxed md:mb-0 mb-16'>
                                <span className='inline-block'>自社メディアを</span>
                                <span className='inline-block'>運営する強みと、</span><br />
                                <span className='inline-block'>プロフェッショナル</span>
                                <span className='inline-block'>による映像制作を両立。</span>
                            </h2>
                        </section>
                        <section className='my-32'>
                            <div className='md:ml-32 md:flex items-center justify-center'>
                                <div className='lg:w-1/2 text-center'>
                                    <p className='lg:text-xl sm:text-sm text-[0.7rem]'>メディアプラットフォーム事業</p>
                                    <a href="https://one-suite.jp/" target='_blank' rel="noreferrer">
                                        <StaticImage src='../images/Headers/onesuite_logo.png' alt='onesuite_logo' className='max-w-[640px] md:w-[25vw] sm:w-[45vw] w-[60vw]'/>
                                    </a>
                                </div>
                                <p className='lg:w-1/2 md:ml-16 fullhd:text-3xl fullhd:leading-loose xl:text-2xl xl:leading-loose xxl:text-xl xxl:leading-loose xmd:text-center xmd:mt-4'>
                                    情報が飽和した時代に<br />
                                    <span className='xsm:inline-block'>本気で読者の</span>
                                    <span className='xsm:inline-block'>最適解について考える</span>
                                    <span className='xsm:inline-block'>Webメディア。</span><br />
                                    <ExternalLink href="https://one-suite.jp/">one-suite.jp</ExternalLink>
                                </p>
                            </div>
                            <div className='md:ml-32 md:flex items-center justify-center mt-24'>
                                <div className='lg:w-1/2 text-center'>
                                    <p className='lg:text-xl sm:text-sm text-[0.7rem]'>映像制作事業</p>
                                    <StaticImage src='../images/ServicePage/onesel_logo_service.png' alt='onesel_logo' className='max-w-[640px] md:w-[25vw] sm:w-[45vw] w-[60vw]'/>
                                </div>
                                <p className='lg:w-1/2 md:ml-16 fullhd:text-3xl fullhd:leading-loose xl:text-2xl xl:leading-loose xxl:text-xl xxl:leading-loose xmd:text-center xmd:mt-4'>
                                    映像のチカラで、<br />
                                    <span className='xsm:inline-block'>心動かすクリエイティブ。</span>
                                    <span className='xsm:inline-block'>視覚で直感的に</span><br />
                                    魅力を伝える映像を提供。
                                </p>
                            </div>
                        </section>
                    </Container>
                </Article>
            </Layout>
      </div> 
    )
}

export default Service

export function Head({ location }) {
    return (
      <MetaTag
        pathname={location.pathname}
        title='service | ONESEL'
      >
      </MetaTag>
    )
  }